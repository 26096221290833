import React, {useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {definition as faSearch} from "@fortawesome/free-solid-svg-icons/faSearch";
import {createQueryParams, useSearch, useSearchQuery} from "../../../../reducers/product/search";
import {useHistory} from "react-router-dom";
import {useSearchBarState} from "../../../../reducers/ui/searchBar";
import {useQuickSearchPopupState} from "../../../../reducers/ui/quickSearchPopup";
import {isSuccess} from "../../../../reducers/networkStateReducer";
import {useRouteMatch} from "react-router";
import {useDebouncedState} from "../../../common/util";
import {useI18n} from "../../../../i18n/I18nSupport";
import {useTracking} from "../../../../tracking/trackingProvider";

interface SearchPopupProps {
    setHeight(height: number):void
}

export default function SearchPopup({setHeight}: SearchPopupProps) {

    const route = useRouteMatch<string>("/search")
    const history = useHistory()

    let [query] = useSearchQuery();

    const [isActive] = useSearchBarState();
    const [input, setInput, debounced] = useDebouncedState<string>(300, "")

    let [searchResult, search] = useSearch("quick-search");
    let [quickSearchPopup,setQuickSearchPopupState] = useQuickSearchPopupState();
    const [isClicked, setClicked] = useState(false)

    const searchLink = useRef<HTMLButtonElement>(null);
    const searchInput = useRef<HTMLInputElement>(null)
    const ref = useRef<HTMLDivElement>(null)

    let {ts} = useI18n("header.search");
    const {track} = useTracking();

    useEffect(() => {
        if (isSuccess(searchResult))  {
            setQuickSearchPopupState(!!input.length && !!searchResult.data.edges.length, searchInput?.current?.getBoundingClientRect()?.bottom ?? 0)
        }
    }, [searchResult, input, searchInput])

    useEffect(() => {
        if (!!debounced?.length) {
            search({
                query: debounced,
                first: 6,
                after: 0,
                source: "search"
            })
        }
    }, [debounced])

    useEffect(() => {
        if (query.query) {
            setInput(query.query)
        }
    }, [query.query])


    useEffect(() => {
        if (route?.url !== '/search' && !!input) {
            setInput("")
        }
        setClicked(false)
    },[isClicked, window.location.href])

    useEffect(() => {
        if (ref.current && ref.current.clientHeight) {
            let popupHeight = ref.current.clientHeight
            setHeight(popupHeight)
        }
    },[!isActive])

    return <>
        <div className={`header-second-menu__search-input ${isActive && `active`}`} ref={ref}>
            <div className="container" style={{maxWidth: "960px"}}>
                <div className="input-group">
                    <input type="search" className="form-control border-0" placeholder={ts`placeholder`} aria-label="Search"
                           value={input}
                           ref={searchInput}
                           aria-describedby="button-addon1"
                           onChange={(e) => {
                               track({
                                   type: "Search",
                                   data: {
                                       search_string: e.target.value
                                   }
                               })
                               setInput(e.target.value)
                           }}
                           onKeyDown={(e: any) => {
                               if (e.code === "Enter" && !!searchLink.current) {
                                   searchLink.current.click()
                                   setClicked(true)
                               }
                           }}
                    />
                    <div className="input-group-append">
                        <button
                            // to={`/search?${createQueryParams({query:input})}`}
                            ref={searchLink}
                            className={"btn btn-primary"}
                            type={"button"}
                            onClick={() => {
                                history.push(`/search?${createQueryParams({query:input, source: "search"})}`)
                                setQuickSearchPopupState(false, 0)
                                setClicked(true)
                            }}>
                            <FontAwesomeIcon icon={faSearch} size={"lg"}/>
                        </button>
                    </div>
                </div>
            </div>
            {/*<QuickSearchResults/>*/}
        </div>
    </>;
}
