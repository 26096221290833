import {applyMiddleware, combineReducers, createStore, compose} from 'redux'
import {backendConnectorMiddleware} from "../connectors/axios";
import {NetworkState, NetworkStateFamily} from "./networkStateReducer";
import {logger} from 'redux-logger'
import {schemaReducer, TemplateReq} from "./schema";
import {Cart} from "./cart/types";
import {cartMiddleware} from "./cart/middleware";
import {outletReducer, OutletRes} from "./outlet";
import {Address, profileGetReducer, ProfileRes} from "./profile/get";
import {profileCreateReducer, ProfileReq} from "./profile/create";
import {ConsumerProfileReq, profileUpdateBillingAddressReducer} from "./profile/updateBillingAddress";
import {profileUpdateShippingAddressReducer} from "./profile/updateShippingAddress";
import {profileUpdateConsumerProfile} from "./profile/updateProfile";
import {tokenReducer, TokenState, userInfoReducer, UserInfoState} from "./user/userInfo";
import {loginReducer} from "./user/login";
import {logoutReducer} from "./user/logout";
import {paymentReducer} from "./purchase/purchase";
import {OrderDetails} from "./order";
import {orderReducer} from "./order/order";
import {profileMiddleware} from "./profile/middleware";
import {refreshReducer} from "./user/refresh";
import {uiReducer, UiState} from "./ui";
import {ongoingOrderReducer, OrderSearchReq} from "./order/ongoingOrders";
import {paymentCompleteReducer} from "./purchase/completePayment";
import {managedCardReducer, ManagedCardState} from "./managedCards/reducer";
import {managedCardMiddleware} from "./managedCards/middleware";
import {productIdReducer, ProductIdState} from "./product/setProduct";
import {pastOrdersReducer} from "./order/completedOrders";
import {productReducer, ProductState} from "./product";
import {Outlets} from "./outlets/types";
import {findOutletsReducer} from "./outlets/getOutlets";
import {InitConfig, initConfigReducer} from "./initReducer";
import {cartReducer} from "./cart";
import * as Sentry from '@sentry/react'
import {profileUpdateWishListReducer} from "./profile/updateWishListItems";
import {pageReducer, PageState} from "./pages";
import {CityDetails, cityDetailsReducer, CitySearchInfo} from "./cities";
import {getAddressesReducer} from "./profile/getAddresses";
import {saveAddressReducer} from "./profile/saveAddress";
import {findAddressByIdReducer} from "./profile/findAddressById";
import {profileRemoveAddressReducer} from "./profile/removeAddress";
import {updateDefaultBillingAddressReducer} from "./profile/updateDefaultBillingAddress";
import {updateDefaultShippingAddressReducer} from "./profile/updateDefaultShippingAddress";
import {inventoryIdReducer, InventoryIdState} from "./inventoryId";
import { CartState } from './cart';
import { Affiliate, affiliateReducer } from './affiliate';

export interface RootState {
    userInfo: UserInfoState
    token: TokenState
    inventory: InventoryIdState
    outlets: {
        getOutlets: NetworkState<Outlets>
    }
    user: {
        loginState: NetworkState<string>,
        logoutState: NetworkState<string>,
        refreshState: NetworkState<string>
    }
    cart: CartState,
    payment:{
        payment: NetworkState<PaymentResponse>,
        paymentComplete: NetworkState<Cart>
    }
    schema: NetworkState<TemplateReq>
    product: ProductState
    outlet: NetworkState<OutletRes>,
    profile: {
        get: NetworkState<ProfileRes>,
        create: NetworkState<ProfileReq>,
        updateBillingAddress: NetworkState<ProfileReq>,
        updateShippingAddress: NetworkState<ProfileReq>
        updateProfile: NetworkState<ProfileReq>
        updateWishList: NetworkState<ProfileReq>
        getAddresses: NetworkState<Address[]>
        saveAddress: NetworkState<Address>
        findAddressById: NetworkStateFamily<Address>
        removeAddress: NetworkState<Address>
        updateDefaultBillingAddress: NetworkState<ConsumerProfileReq>
        updateDefaultShippingAddress: NetworkState<ConsumerProfileReq>
    }
    order: {
        current: NetworkState<OrderDetails>
        ongoing: NetworkState<OrderSearchReq>
        past: NetworkState<OrderSearchReq>
    },
    uiState: UiState,
    managedCards: ManagedCardState,
    productId: ProductIdState,
    initConfig: InitConfig,
    page: PageState,
    city: CityDetails,
    affiliate: Affiliate
}

const rootReducer = combineReducers<RootState>({
    user: combineReducers({
        loginState: loginReducer,
        logoutState: logoutReducer,
        refreshState: refreshReducer
    }),
    outlets: combineReducers({
        getOutlets: findOutletsReducer
    }),
    userInfo: userInfoReducer,
    token: tokenReducer,
    inventory: inventoryIdReducer,
    cart : cartReducer,
    schema : schemaReducer,
    product: productReducer,
    outlet: outletReducer,
    profile: combineReducers({
        get: profileGetReducer,
        create: profileCreateReducer,
        updateShippingAddress: profileUpdateShippingAddressReducer,
        updateBillingAddress: profileUpdateBillingAddressReducer,
        updateProfile: profileUpdateConsumerProfile,
        updateWishList: profileUpdateWishListReducer,
        getAddresses: getAddressesReducer,
        saveAddress: saveAddressReducer,
        findAddressById: findAddressByIdReducer,
        removeAddress: profileRemoveAddressReducer,
        updateDefaultBillingAddress: updateDefaultBillingAddressReducer,
        updateDefaultShippingAddress: updateDefaultShippingAddressReducer
    }),
    order: combineReducers({
        current: orderReducer,
        ongoing: ongoingOrderReducer,
        past: pastOrdersReducer
    }),
    payment: combineReducers({
        payment: paymentReducer,
        paymentComplete: paymentCompleteReducer
    }),
    uiState: uiReducer,
    managedCards: managedCardReducer,
    productId: productIdReducer,
    initConfig: initConfigReducer,
    page: pageReducer,
    city: cityDetailsReducer,
    affiliate: affiliateReducer
})

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options listed below
});

export const store = createStore(
    rootReducer,
    compose(applyMiddleware(backendConnectorMiddleware, cartMiddleware, profileMiddleware, managedCardMiddleware, /*logger*/), sentryReduxEnhancer),
)
