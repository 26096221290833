import React from "react";
import {toast} from "react-toastify";
import {useCart} from "../../../reducers/cart/get";
import {useEffect, useState} from "react";
import {Cart, CartWarning} from "../../../reducers/cart/types";
import {isSuccess} from "../../../reducers/networkStateReducer";
import {useCartUpdate} from "../../../reducers/cart/update";
import {useCartRemove} from "../../../reducers/cart/remove";
import {useI18n, WithPlaceholders} from "../../../i18n/I18nSupport";
import {useCartSetPromoCode} from "../../../reducers/cart/setPromoCode";

interface LowStock extends CartWarning {
    productId: string
    varianceKey: string
    productName: string
    variances: { [k: string]: string }
    availableQuantity: number
    type: "LOW_STOCK"
}

function isLowStock(warning: CartWarning): warning is LowStock {
    return warning.type === "LOW_STOCK"
}

function LowStockWarning({warning, itemKey}: {warning: LowStock, itemKey: string}) {

    const [, update] = useCartUpdate(itemKey);
    const [cart] = useCart();

    let {tx} = useI18n('warnings.cart.lowstock');

    useEffect(() => {

        let content = <WithPlaceholders value={{
            warning,
            count: warning.availableQuantity
        }}>
            <div className={"d-flex align-items-center justify-content-between"}>
                <div style={{width: "70%", maxWidth: "70%"}}>{tx`msg`}</div>
                <button type={"button"} className={"btn btn-outline-light ml-3 flex-grow-1"} onClick={() => {
                    if (isSuccess(cart)) {
                        let item = cart.data.items[itemKey];
                        if (!!item ){
                            update({
                                quantity: warning.availableQuantity,
                                productId: warning.productId,
                                selected: true,
                                variance: warning.varianceKey
                            })
                        }
                    }
                }}>{tx`btn`}</button>
            </div>
        </WithPlaceholders>

        let id = toast.error(content, {
            position: "top-center",
            closeOnClick: false,
            closeButton: false
        });
        return () => toast.dismiss(id)
    }, [])

    return null;
}

interface Unavailable extends CartWarning {
    productId: string
    varianceKey: string
    productName: string
    variances: { [k: string]: string }
    type: "UNAVAILABLE"
}

function isUnavailable(warning: CartWarning): warning is Unavailable {
    return warning.type === "UNAVAILABLE"
}

function UnavailableWarning({warning, itemKey}: {warning: Unavailable, itemKey: string}) {

    const [, remove] = useCartRemove();
    const [cart] = useCart();
    let {tx} = useI18n('warnings.cart.unavailable');

    useEffect(() => {
        let content = <WithPlaceholders value={{ warning }}>
            <div className={"d-flex align-items-center justify-content-between"}>
                <div style={{width: "70%", maxWidth:"70%"}}>{tx`msg`}</div>
                <button type={"button"} className={"btn btn-outline-light ml-3 flex-grow-1"} onClick={() => {
                    if (isSuccess(cart)) {
                        let item = cart.data.items[itemKey];
                        if (!!item ){
                            remove(warning.productId, warning.varianceKey)
                        }
                    }
                }}>{tx`btn`}</button>
            </div>
        </WithPlaceholders>

        let id = toast.error(content, {
            position: "top-center",
            closeOnClick: false,
            closeButton: false,
            autoClose: 5000
        });
        return () => toast.dismiss(id)
    }, [])

    return null;
}

interface InvalidPromoCode extends CartWarning {
    promoCode: string
    reason: string
    type: "UNAVAILABLE"
}

function isInvalidPromo(warning: CartWarning): warning is InvalidPromoCode {
    return warning.type === "INVALID_PROMO_CODE"
}

function InvalidPromoWarning({warning}: {warning: InvalidPromoCode}) {

    let [, setPromoCode] = useCartSetPromoCode();
    const [cart] = useCart();

    useEffect(() => {
        let content = <>
            Discount - {warning.promoCode} is invalid. Do you want to remove discount from the cart?
            <button type={"button"} className={"btn btn-outline-light ml-3"} onClick={(e) => {
                if (isSuccess(cart)) {
                    setPromoCode(undefined)
                }
            }}>Remove</button>
        </>

        let id = toast.error(content, {
            position: "top-center",
            closeOnClick: false,
            closeButton: false
        });
        return () => toast.dismiss(id)
    }, [])

    return null;
}


interface ProductDisabled extends CartWarning {
    productId: string
    productName: string
    varianceKey: string
    type: "PRODUCT_DISABLED"
}

function isProductDisabled(warning: CartWarning): warning is ProductDisabled {
    return warning.type === "PRODUCT_DISABLED"
}

function ProductDisabledWarning({warning, itemKey}: {warning: ProductDisabled, itemKey: string}) {

    const [, remove] = useCartRemove();
    const [cart] = useCart();
    let {tx} = useI18n('warnings.cart.productDisabled');

    useEffect(() => {
        let content = <WithPlaceholders value={{ warning }}>
            <div className={"d-flex align-items-center justify-content-between"}>
                <div style={{width: "70%", maxWidth:"70%"}}>{tx`msg`}</div>
                <button type={"button"} className={"btn btn-outline-light ml-3 flex-grow-1"} onClick={() => {
                    if (isSuccess(cart)) {
                        let item = cart.data.items[itemKey];
                        if (!!item ){
                            remove(warning.productId, warning.varianceKey)
                        }
                    }
                }}>{tx`btn`}</button>
            </div>
        </WithPlaceholders>

        let id = toast.error(content, {
            position: "top-center",
            closeOnClick: false,
            closeButton: false
        });
        return () => toast.dismiss(id)
    }, [])

    return null;
}


export function CartWarnings() {

    let [localCart, setLocalCart] = useState<Cart>();

    const [cart] = useCart();

    useEffect(() => {
        if (isSuccess(cart)) {
            setLocalCart(cart.data)
        }
    }, [cart])

    if (!localCart) return null

    return <>
        {Object.entries(localCart.warnings).map(([key, value]) => {
            if (isLowStock(value)) {
                return <LowStockWarning warning={value} key={key} itemKey={key}/>
            }
            if (isUnavailable(value)) {
                return <UnavailableWarning warning={value} key={key} itemKey={key}/>
            }
            // if (isInvalidPromo(value)) {
            //     return <InvalidPromoWarning warning={value}/>
            // }
            if (isProductDisabled(value)) {
                return <ProductDisabledWarning warning={value} key={key} itemKey={key}/>
            }
            return null
        })}
    </>
}
