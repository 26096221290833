import React, {ImgHTMLAttributes, useCallback, useEffect, useRef, useState} from "react";
import {components} from "../../__generated/api";
import {createImgProxyUrl} from "./createImageProxy";

export type ResponsiveImage = components["schemas"]["ResponsiveImage"]

function createImage(imageUrl: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', error => reject(error))
        image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
        image.src = imageUrl
    })
}

function responsiveUrl(url: string | undefined, platform: string): string | undefined {
    if (!url) return url

    let [ext, ...rest] = url.split('.').reverse()
    return [ext, platform, ...rest].reverse().join('.')
}

export function ImageViewPort({value, ...props}: ImgHTMLAttributes<HTMLImageElement> & {value: ResponsiveImage}) {

    return <>
        <picture>
            {value.mobile && <source srcSet={createImgProxyUrl(responsiveUrl(value.imageUrl, 's'), 576, 576 * 3 / 4)} media={"(max-width:576px)"}/>}
            {value.tablet && <source srcSet={createImgProxyUrl(responsiveUrl(value.imageUrl, 'm'), 768, 768 *  9 / 16)} media={"(max-width:768px)"}/>}
            {value.desktop && <source srcSet={createImgProxyUrl(responsiveUrl(value.imageUrl, 'l'), 1920, 1920 * 2 / 5)} media={"(min-width:769px)"}/>}
            <img {...props} src={createImgProxyUrl(responsiveUrl(value.imageUrl, 'l'), 1920, 1920 * 2 / 5)}/>
        </picture>
    </>
}
