import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { Body } from "./Body/Body";
import Footer from "./Footer/Footer";
import { TemplateRes, useSchema } from "../../reducers/schema";
import { isError, isInit, isPending, isSuccess } from "../../reducers/networkStateReducer";
import { Helmet } from 'react-helmet-async'
import { useKeycloak } from "@react-keycloak/web";
import { useRefreshCallback } from "../../reducers/user/refresh";
import AddToCartQuickView from "./AddToCartQuickView";
import { createUseStyles } from 'react-jss'
import { useErrorStatus } from "../../reducers/ui/errorStatus";
import { DateTime } from "luxon";
import { useI18n, WithPlaceholders } from "../../i18n/I18nSupport";
import { ErrorBoundary } from "@sentry/react";
import { configureI18n } from "../../i18n/i18nMessages";
import { TFunction } from "i18next";
import { Header } from './Header/Header';
import { useTracking, WithTrackingData } from "../../tracking/trackingProvider";
import { useLocation } from 'react-router';
import { toast } from "react-toastify";
import { useConsumerProfileUpdate } from "../../reducers/profile/updateProfile";
import { useProfileGet } from "../../reducers/profile/get";
import { createImgProxyUrl } from "../common/createImageProxy";
import MultiStoreSelection from "./Body/MultiStoreSelection/MultiStoreSelection";
import { useLocalStorage } from "./Body/Checkout/CheckoutFlowRevamp/utils";
import { setInventoryId } from "../../reducers/inventoryId";
import { useDispatch } from "react-redux";
import StoreSwitchPopUp from "./Header/StoreSwitchPopUp";
import { useMultiStore } from "../../reducers/ui/multiStoreSelection";
import { useDefaultCurrencyCode } from "../../reducers/ui/defaultCurrencyCode";
import { useDefaultLanguage } from "../../reducers/ui/defaultLanguage";
import { useSetSelectedCurrency } from "../../reducers/cart/setSelectedCurrency";
import { useCart } from "../../reducers/cart/get";
import { FooterRevamp } from "./Footer/FooterRevamp";
import {keycloak} from "../../auth/AuthProvider";
import {components} from "../../__generated/api";

export type PopUpConfig = components["schemas"]["PopUpConfig"]
export type GreetingPopUpConfig = components["schemas"]["GreetingPopUpConfig"]
export type CookiesConsentModalConfig = components["schemas"]["CookiesConsentModalConfig"]



const useStyles = createUseStyles({
    styles: {

    }
})
interface ContentProps {
    useStyles: any
    schema: TemplateRes
}

function Content({ useStyles, schema }: ContentProps) {

    const { styles } = useStyles();
    let { ts } = useI18n('header');
    let { pathname } = useLocation();
    const { track } = useTracking();
    const [getItem, setItem, removeItem] = useLocalStorage<{ inventoryId: string }>();
    const [multiSelectionEnabled, setMultiSelectionEnabled] = useMultiStore();
    const dispatch = useDispatch();
    const [showPopUp, setShowPopUp] = useState(false)



    let merchantStructuredData: any = useMemo(() => {
        return {
            "@context": "https://schema.org/",
            "@type": "Organization",
            "name": `${schema.merchantName}`,
            "image": [
                `${window.location.origin}${createImgProxyUrl(schema.announcementBanner, 700, 700)}`,
                `${window.location.origin}${createImgProxyUrl(schema.brandLogo, 700, 700)}`,
                `${window.location.origin}${createImgProxyUrl(schema.backgroundImage, 700, 700)}`,
                `${window.location.origin}${createImgProxyUrl(schema.banner, 700, 700)}`
            ],
            "knowsAbout": `${schema.footer.aboutUs}`,
            "email": `${schema.footer.email}`,
            "telephone": `${schema.footer.telephone}`,
            "description": `${schema.seoConfigurations?.merchantConfigurations?.description}`,
            "url": `${schema.siteUrl ?? "https://appigo.co/"}`,
            "location": `${schema.country}`,
            "address": `${schema.footer.address},${schema.footer.addressTwo}`,
            "knowsLanguage": `${JSON.stringify(schema.languages)}`,
            "logo": `${window.location.origin}${schema.footer.logoURL}`,
        };
    }, [schema]);

    useEffect(() => {
        track({
            type: "PageView",
            data: {
                path: pathname
            }
        })
    }, [pathname])

    useEffect(() => {
        if (schema.popUpConfig?.isEnabled) {
            if(sessionStorage.getItem("popupShown") == "true" && schema.popUpConfig.isOnlyShownFirstTime){
                setShowPopUp(false)
            } else setShowPopUp(true)
        }
    }, [])

    useEffect(() => {
        if (!!schema?.inventoryLocations) {
            if (schema.inventoryLocations.length == 1) {
                setMultiSelectionEnabled(false)
                setItem(`inventoryId`, { value: { inventoryId: schema.inventoryLocations[0].id } })
                dispatch(setInventoryId(schema.inventoryLocations[0].id))
            } else {
                if (!!getItem('inventoryId')) {
                    if (schema.inventoryLocations?.find((inventory) => inventory.id == getItem('inventoryId')?.inventoryId)) {
                        setMultiSelectionEnabled(false)
                        dispatch(setInventoryId(getItem('inventoryId').inventoryId))
                    } else {
                        removeItem("inventoryId")
                    }
                }
            }
        } else {
            setMultiSelectionEnabled(false)
        }
    }, [schema])

    return <div className={`d-flex flex-column justify-content-between ${styles}`} style={{ minHeight: "100vh" }}>
        {/*{showPopUp &&*/}
        {/*    <PopUp  popupConfig={schema.popUpConfig}/>}*/}
        <Helmet>
            <title>{ts`title`} {/*{schema.merchantName}*/}</title>
            <script type="application/ld+json">{JSON.stringify(merchantStructuredData)}</script>
            <link rel="icon" href={schema.brandLogo ?? `/merchant-resources/white_logo.png`} />
            <link rel="apple-touch-icon" sizes="57x57" href={schema.brandLogo ?? `/merchant-resources/white_logo.png`} />
            <link rel="apple-touch-icon" sizes="60x60" href={schema.brandLogo ?? `/merchant-resources/white_logo.png`} />
            <link rel="apple-touch-icon" sizes="72x72" href={schema.brandLogo ?? `/merchant-resources/white_logo.png`} />
            <link rel="apple-touch-icon" sizes="76x76" href={schema.brandLogo ?? `/merchant-resources/white_logo.png`} />
            <link rel="apple-touch-icon" sizes="114x114" href={schema.brandLogo ?? `/merchant-resources/white_logo.png`} />
            <link rel="apple-touch-icon" sizes="120x120" href={schema.brandLogo ?? `/merchant-resources/white_logo.png`} />
            <link rel="apple-touch-icon" sizes="144x144" href={schema.brandLogo ?? `/merchant-resources/white_logo.png`} />
            <link rel="apple-touch-icon" sizes="152x152" href={schema.brandLogo ?? `/merchant-resources/white_logo.png`} />
            <link rel="apple-touch-icon" sizes="180x180" href={schema.brandLogo ?? `/merchant-resources/white_logo.png`} />
            <link rel="icon" type="image/png" sizes="192x192" href={schema.brandLogo ?? `/merchant-resources/white_logo.png`} />
            <link rel="icon" type="image/png" sizes="32x32" href={schema.brandLogo ?? `/merchant-resources/white_logo.png`} />
            <link rel="icon" type="image/png" sizes="96x96" href={schema.brandLogo ?? `/merchant-resources/white_logo.png`} />
            <link rel="icon" type="image/png" sizes="16x16" href={schema.brandLogo ?? `/merchant-resources/white_logo.png`} />
            <meta name="msapplication-TileColor" content="#ffffff"/>
            <meta name="theme-color" content="#ffffff"/>
        </Helmet>
        <div>
            <Header />
            {multiSelectionEnabled ?
                <MultiStoreSelection inventoryLocations={schema.inventoryLocations} /> : <Body />}
            <FooterRevamp />
            <AddToCartQuickView />
            <StoreSwitchPopUp schema={schema} />
        </div>
    </div>
}

function* backoffDelayGenerator(): Generator<number> {
    yield 2;
    yield 3;
    yield 6;
    yield 16;
    yield 31;
    yield 61;
    while (true) {
        yield 61;
    }
}

function UnderMaintenancePage() {
    let [nextCheckTime, setNextCheckTime] = useState<DateTime>();
    const [schema, fetchSchema] = useSchema();
    const backoffDelayProvider = backoffDelayGenerator()
    const [, setCounter] = useState(0)

    useEffect(() => {
        let timeoutId: any | undefined = undefined
        function recur() {
            if (!!timeoutId) {
                clearTimeout(timeoutId)
            }
            let backoffTime = backoffDelayProvider.next().value;
            setNextCheckTime(DateTime.local().plus({ seconds: backoffTime }))
            timeoutId = setTimeout(() => {
                fetchSchema()
                recur()
            }, backoffTime * 1000)
        }
        recur()
        return () => {
            if (!!timeoutId) {
                clearTimeout(timeoutId)
            }
        }
    }, [])

    useEffect(() => {
        let intervalId = setInterval(() => {
            setCounter(c => c + 1)
        }, 1000);

        return () => clearInterval(intervalId)
    }, [])

    return <>
        <div className="container-fluid bg-white d-flex justify-content-center align-items-center flex-column" style={{ height: "100vh" }}>
            {/*<div className="row d-flex justify-content-center">*/}
            {/*    <img src={isSuccess(schema) ? schema.data.brandLogo : ""} style={{width:"25rem", height:"auto"}}/>*/}
            {/*</div>*/}
            <div className="row d-flex p-2 flex-column text-center">
                <h2><strong>We are sorry, Site is temporarily offline</strong></h2>
                <span style={{ fontSize: "1.2em" }}>Our website is currently undergoing scheduled maintenance. We should be back shortly. Thank you for your patience.</span>
            </div>
            <div className="row m-0 d-flex justify-content-center">
                <img src={'/merchant-resources/page_under_construction.jpg'} style={{ width: "27em", height: "auto" }} />
            </div>
            <div className="row d-flex flex-column text-center">
                <h4>
                    {isPending(schema) && <>Loading...</>}
                    {!isPending(schema) && <button style={{ fontSize: "0.7em" }} className={"btn btn-info rounded-pill"} onClick={() => fetchSchema()}>Retry now...</button>}
                </h4>
            </div>
        </div>
    </>
}

export default function MainPage() {

    const { initialized } = useKeycloak();
    const [, refreshCallback] = useRefreshCallback();
    const [errorStatus] = useErrorStatus();
    const { styles } = useStyles();
    const [profile, fetchProfile] = useProfileGet()
    let [updatedProfile, updateProfileInfo] = useConsumerProfileUpdate()
    const [currency, setCurrency] = useDefaultCurrencyCode();
    const [language, setLanguage] = useDefaultLanguage();
    const [cart] = useCart();
    let [schema, fetchSchema] = useSchema()


    const [selectedCurrency, setSelectedCurrency, resetSetCurrency] = useSetSelectedCurrency();

    useEffect(() => {
        if (initialized) {
            refreshCallback()
            fetchProfile()
        }
    }, [initialized])

    useEffect(() => {
        if (isSuccess(profile)) {
            if (profile.data.consumerProfileEmailVerified) {
                toast.success("Your email address has been verified", {
                    closeOnClick: false,
                    closeButton: false,
                    toastId: "email verification",
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    bodyStyle: {
                        textAlign: "center",
                        fontSize: "1.2rem"
                    },
                });
                updateProfileInfo({ ...profile.data, emailVerified: true })
            }
        }

    }, [profile])


    useEffect(() => {
        fetchSchema()
    }, [])

    useEffect(() => {
        if (isSuccess(schema)) {
            let data = schema.data
            setCurrency(schema.data.currencies.find(currency => data.currency == currency) ?? schema.data.currencies[0] ?? "USD|$")

            if(!(schema.data.generalSettings?.isStorePublic ?? true) && !keycloak.authenticated){
                keycloak.login()
            }
        }
    }, [schema])

    useEffect(() => {
        withCartChange()
    }, [currency, cart])

    useEffect(() => {
        if (!!currency) {
            configureI18n({
                currencyCode: currency,
                language: language
            })
        }
    }, [currency, language])

    let withCartChange = useCallback(() => {
        if (isSuccess(cart) && cart.data.selectedCurrency !== currency && (cart.data.orderStatus !== "ON_PAYMENT" && cart.data.orderStatus !== "ORDER_PLACED")) {
            setSelectedCurrency(currency)
        }
    }, [currency, cart])

    if (errorStatus?.errorCode === "E2003") {
        return <UnderMaintenancePage />
    }

    if (isPending(schema) || isInit(schema)) {
        return null
    }

    if (isError(schema)) {
        return null
    }

    if (isSuccess(schema)) {
        return <WithTrackingData data={{
            merchantId: schema.data.merchantId,
            currency: schema.data.currency?.split("|")[0]
        }}>
            <WithPlaceholders value={{
                schema: schema.data
            }}>
                <ErrorBoundary onError={e => console.error(e)}>
                    <Suspense fallback={() => <></>}>
                        <Content useStyles={createUseStyles({ styles: JSON.parse(schema.data.styles ?? "{}") })}
                            schema={schema.data} />
                    </Suspense>
                </ErrorBoundary>
            </WithPlaceholders>
        </WithTrackingData>
    }
    return null
}

