import React from "react";
import {Product} from "../../../../../reducers/product/types";
import {ProductImageSlider} from "./ProductImageSlider";
import {ProductInformation} from "./ProductInformation";
import {ProductStateSection} from "./ProductStateSection";

export interface ProductInfoSectionProps {
    product: Product
}

export function ProductInfoSection({product}: ProductInfoSectionProps) {
    return <div className="card-body">
        <div className="row">
            <div className="col-sm-4">
                <ProductImageSlider product={product} key={product.id}/>
            </div>
            <div className="col-sm-4 mt-2 mt-sm-0">
                <ProductInformation product={product}/>
            </div>
            <div></div>
            <div className="col-sm-4">
                <ProductStateSection product={product}/>
            </div>
        </div>
    </div>
}
