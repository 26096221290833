import React, { useEffect, useRef, useState } from 'react';
import { usePromotionPopUp } from "../../../reducers/ui/PromotionMessagePopup";
import { useHeaderHeightState } from "../../../reducers/ui/HeaderHeight";
import { useSearchBarState } from "../../../reducers/ui/searchBar";
import { QuickSearchResults } from "./SearchResults";
import { Link, useLocation } from "react-router-dom";
import { TemplateRes, useSchema } from "../../../reducers/schema";
import { isSuccess } from "../../../reducers/networkStateReducer";
import { Menu } from "./MenuBar_Old/Categories";
import { useMobileMenuState } from "../../../reducers/ui/mobilemenu";
import { CartWarnings } from "./CartWarnings";
import { useI18n } from "../../../i18n/I18nSupport";
import i18n from "i18next";
import { NavMenu } from "./MenuBar/NavMenu";
import { TopBarMenu } from "./TopBar/TopBarMenu";
import { MenuBar } from "./MenuBar_Old/MenuBar";
import { TopBar } from "./TopBar_Old/TopBar";
import { useLocalStorage } from "../Body/Checkout/CheckoutFlowRevamp/utils";

function PromotionalBanner() {

    const [schema] = useSchema();
    const { pathname } = useLocation();
    let [_, setPromotionBannerState] = useState(false)
    let [currSchema, setCurrSchema] = useState<TemplateRes>()
    let { tx } = useI18n('header');

    useEffect(() => {
        if (isSuccess(schema)) {
            setCurrSchema(schema.data)
        }
    }, [schema])

    useEffect(() => {
        if (!!currSchema && currSchema.announcementBanner?.showAnnounceBar) {
            setPromotionBannerState(currSchema.announcementBanner?.showAnnounceBar)
        }
    }, [currSchema])

    if (!isSuccess(schema)) return null;

    if (!schema.data.announcementBanner) return null

    if (schema.data.announcementBanner.homePageOnly && pathname !== '/') return null

    return <div className={`header-promotion-banner promoBanner`}>
        <Link to={schema.data.announcementBanner?.link ?? ""}>
            <p className="my-2 promoBannerText">{tx`announcement`}</p>
        </Link>
    </div>
}

export function Header() {
    let [mobileMenuStatus] = useMobileMenuState()
    let [schema] = useSchema()
    let [, setHeaderHeight] = useHeaderHeightState()
    const [searchBarState] = useSearchBarState();
    const [promoBannerState] = usePromotionPopUp();
    let [, setMenus] = useState<Menu[]>()
    let [searchPopupHeight, setSearchPopupHeight] = useState<number>(0)
    const [getItem] = useLocalStorage();
    const [showStickyNav, setShowStickyNav] = useState<boolean>(false)

    useEffect(() => {
        const onScroll = () => {
            const headerHeight = ref?.current?.clientHeight || 0;
            setShowStickyNav(!!headerHeight && window.pageYOffset >= headerHeight);
        }

        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    useEffect(() => {
        if (isSuccess(schema)) {
            setMenus(schema.data.header.menus)
        }
    }, [schema])

    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (ref.current && ref.current.clientHeight) {
            let height = ref.current.clientHeight;
            setHeaderHeight(height);
        }

    }, [searchBarState, promoBannerState, window.location.pathname, i18n.language, ref.current?.clientHeight]);

    return <>
        <div>
            <CartWarnings />
            <header ref={ref} className={`appigo-header absolute-top fixed-top-on-mobile pb-lg-0 pb-1 ${mobileMenuStatus ? 'mobile-menu-open' : 'mobile-menu-close'}`}>
                <PromotionalBanner />
                {/*<TopBar searchPopupHeight={searchPopupHeight}/>*/}
                {/*<MenuBar setHeight={setSearchPopupHeight}/>*/}
                <TopBarMenu searchPopupHeight={searchPopupHeight} />
                {!!getItem('inventoryId') && <NavMenu setHeight={setSearchPopupHeight} />}
            </header>
            <QuickSearchResults />
        </div>

        {!!getItem('inventoryId') &&
            <div className='hide-on-mobile' style={{ display: showStickyNav ? 'contents' : 'none' }}>
                <div className='sticky-top'>
                    <NavMenu setHeight={setSearchPopupHeight} showSearchBar={false} showCategoryBarLogo={true} />
                </div>
            </div>
        }
    </>
}
