import React, {createContext, PropsWithChildren, useContext, useEffect} from 'react'
import {useProfileGet} from "../reducers/profile/get";
import {isSuccess} from "../reducers/networkStateReducer";

export interface TrackingEvent {
    type: 'AddPaymentInfo' | 'AddToCart' | 'AddToWishlist' | 'CompleteRegistration' | 'Contact' | 'CustomizeProduct' | 'Donate' | 'FindLocation' | 'InitiateCheckout' |
        'Lead' | 'PageView' | 'Purchase' | 'Schedule' | 'Search' | 'StartTrial' | 'SubmitApplication' | 'Subscribe' | 'ViewContent' | 'PaymentAndBilling' | 'AddShippingDetails' |
        'PlaceOrderCOD' | 'PlaceOrderCard' | 'IPGFailure' | 'PromoApply' | 'PuchaseFailed' | 'Purchase-GA' | 'PaymentCompleteCOD' | 'PaymentComplete'
    data: { [k: string]: any }
}

export interface TrackingAdaptor {
    track(trackingEvent: TrackingEvent): void
}

interface TrackingContext {
    data: { [k: string]: any }
    adaptors: TrackingAdaptor[]
}

const TrackingContext = createContext<TrackingContext>({
    data: {},
    adaptors: []
})

export function TrackingProvider({children, adaptors}: PropsWithChildren<{ adaptors: TrackingAdaptor[] }>) {
    return <TrackingContext.Provider value={{
        data: {},
        adaptors
    }}>
        {children}
    </TrackingContext.Provider>
}

interface WithTrackingDataProps {
    data: { [k: string]: any }
}

interface CookiesConfig {
    isAllowCookie: boolean;
    isCookieClicked?: boolean;
}

export function WithTrackingData({data: newData, children}: PropsWithChildren<WithTrackingDataProps>) {
    let {data, adaptors} = useContext(TrackingContext);
    return <TrackingContext.Provider value={{data: {...data, ...newData}, adaptors}}>
        {children}
    </TrackingContext.Provider>
}

export interface TrackingHookType {
    track(event: TrackingEvent): void
}

export function useTracking(): TrackingHookType {
    let {data: existingData, adaptors} = useContext(TrackingContext);
    const [profile, getProfile] = useProfileGet();

    useEffect(() => {
        getProfile()
    }, []);


    return {
        track(event: TrackingEvent) {
            console.log("Tracking Events",event, profile)
            if (
                !isSuccess(profile) ||
                (isSuccess(profile) &&
                    profile.data?.cookiesConfig?.isAllowCookie === true
                )
            ) {
                console.log("Tracking Events with Success",event, profile)
                for (let adaptor of adaptors) {
                    try {
                        adaptor.track({...event, data: {...existingData, ...event.data}});
                    } catch (e) {
                        console.warn('An error occurred while tracking', e);
                    }
                }
            }

        }
    }
}
