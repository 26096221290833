import React, { useEffect } from "react";
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { definition as faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import { WidgetSection } from "../Home";
import { createQueryParams, parseQueryParams, useSearch } from "../../../../../reducers/product/search";
import { isSuccess } from "../../../../../reducers/networkStateReducer";
import Carousel from "react-bootstrap/Carousel";
import { SearchReq } from "../../../../../reducers/product/types";
import { useI18n, WithPlaceholders } from "../../../../../i18n/I18nSupport";
import { ProductCard } from "../../../../common/ProductCard";
import { WishListItem } from "../../../../../reducers/profile/updateWishListItems";
import { faChevronLeft, faChevronRight, faEye } from "@fortawesome/free-solid-svg-icons";

interface WidgetSectionProps {
    props: WidgetSection,
    wishList: WishListItem[]
}

interface EmptyProductCardProps {
    params: SearchReq,
}

function EmptyProductCard({ params }: EmptyProductCardProps) {

    return <div className="col-sm-3 col-4">
        <Link to={`/search?${createQueryParams(params)}`}>
            <div className="card border-0 text-center">
                <div className="products-section__empty-card d-flex flex-column"
                    style={{ padding: 0, justifyContent: "center", alignItems: "center" }}>
                    <img src="/merchant-resources/products-icon.png" />
                    <p className="mt-3">View All <FontAwesomeIcon icon={faArrowRight} size={"lg"} /></p>
                    {/*<ProductDescription/>*/}
                </div>
            </div>
        </Link>
    </div>

}

export function chunck(size: number, input: any[]): any[][] {
    let arr = []

    if (input.length < size) return [input]

    for (let i = 0; i < input.length; i += size) {
        arr.push(input.slice(i, i + size))
    }

    return arr
}

export function Widget({ props, wishList }: WidgetSectionProps) {

    const [result, search] = useSearch(props.label?.en ?? "")
    const params = parseQueryParams(props.query ?? "")
    const { tx } = useI18n('home.widget');

    useEffect(() => {
        search({ ...params, first: 8, after: 0, source: "widget" })
    }, [])

    if (!isSuccess(result) || (isSuccess(result) && !result?.data?.edges?.length)) return null;

    return <WithPlaceholders value={{
        widget: props
    }}>
        <div className="main-section pt-0">
            <div className="container">
                <div className="product-carousel-container">
                    <div className="row">
                        <div className="col-10">
                            <h3 className="font-weight-bold product-carousel-title">{tx`label`}</h3>
                        </div>
                        <div className="col-5 col-md-4 col-lg-2 text-right ml-auto mb-2" style={{ position: "sticky" }}>
                            <Link to={`/search?${createQueryParams(params)}`}
                                className="py-2 mb-2 float-right view-all-button-text">
                                {tx`viewBtn`}
                                <FontAwesomeIcon className="view-all-icon" icon={faEye} size={"1x"} />
                            </Link>
                        </div>
                    </div>
                    <div className="products-section d-none d-lg-block d-xl-block" style={{ marginBottom: "1px" }}>
                        <Carousel controls={true} className={"mb-0"} 
                            prevIcon={<FontAwesomeIcon className="carusel-nav-icon" icon={faChevronLeft} size={"2x"} />}
                            nextIcon={<FontAwesomeIcon className="carusel-nav-icon" icon={faChevronRight} size={"2x"} />}
                        >
                            {
                                isSuccess(result) && chunck(4, result.data.edges.map(v => v.node)).map((data) =>
                                    <Carousel.Item>
                                        <div className="row mx-0">
                                            {
                                                data.map((node, key) => <ProductCard key={key} item={node}
                                                    isInStock={node.isInStock}
                                                    isAvailable={wishList.map(value => value.baseId).some((v) => v == node.baseId)} />)
                                            }
                                        </div>
                                    </Carousel.Item>
                                )
                            }
                        </Carousel>
                    </div>
                    <div className="products-section d-lg-none d-xl-none" style={{ marginBottom: "1px" }}>
                        <Carousel controls={false} className={"mb-0"}>
                            {
                                isSuccess(result) && chunck(2, result.data.edges.map(v => v.node)).map((data) =>
                                    <Carousel.Item>
                                        <div className="row">
                                            {

                                                data.map((node, key) => <ProductCard key={key} item={node}
                                                    isInStock={node.isInStock}
                                                    isAvailable={wishList.map(value => value.baseId).some((v) => v == node.baseId)} />)

                                            }
                                        </div>
                                    </Carousel.Item>
                                )
                            }
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    </WithPlaceholders>
}
