import React, {useEffect, useState} from "react";
import {useSearch} from "../../../reducers/product/search";
import {isSuccess} from "../../../reducers/networkStateReducer";
import {Link} from 'react-router-dom'
import {useQuickSearchPopupState} from "../../../reducers/ui/quickSearchPopup";
import {useSearchBarState} from "../../../reducers/ui/searchBar";
import {ProductConnection, ProductSearchView} from "../../../reducers/product/types";
import {useMediaQuery} from 'react-responsive';
import {useI18n, WithPlaceholders} from "../../../i18n/I18nSupport";
import {createImgProxyUrl} from "../../common/createImageProxy";
import {PriceConversion} from "../../common/ProductCard";
import {useDefaultCurrencyCode} from "../../../reducers/ui/defaultCurrencyCode";

interface SearchResultItemProps {
    item: ProductSearchView
}

function SearchResultDetailsWithSpecialPrice() {
    let {tx} = useI18n("header.search.item");

    return <>
        <div className="ml-3">
            <div className="row">
                <h6 className="mt-2 mb-0" style={{
                    color: "gray",
                    position: "relative",
                    bottom: 0,
                    alignItems: "start",
                    fontSize: "12px",
                    textDecoration: "line-through"
                }}>{tx`price|{{item.price|currency}}`}</h6>
                <h6 className="mt-2 mb-0" style={{
                    color: `red`,
                    position: "relative",
                    bottom: 0,
                    alignItems: "end",
                    fontSize: "12px",
                    textAlign: "start",
                    marginLeft: "5px"
                }}>{tx`percentage|({{fraction|percent}} OFF)`}</h6>
            </div>
            <div className="row">
                <div className="d-flex flex-row" style={{alignItems: "space-between", position: "absolute"}}>
                    <h6 className="mt-2 mb-0 font-weight-bold discounted-price-text"
                        style={{fontSize: "14px"}}>{tx`specialPrice|{{item.specialPrice|currency}}`}</h6>
                </div>
            </div>
        </div>
    </>
}

export function SearchResultDetails() {
    let {tx} = useI18n('header.search.item');
    return <div className="d-inline-block ml-1 col-12">
        <div className="row">
            <h6 className="mt-2 mb-0 font-weight-bold price-text" style={{
                position: "relative",
                bottom: 0,
                alignItems: "start",
                fontSize: "14px"
            }}>{tx`price|{{item.price|currency}}`}</h6>
        </div>
        <div className="row">
            <div className="d-flex flex-row" style={{alignItems: "space-between", position: "absolute"}}>
                <h6 className=" mt-2 mb-0 font-weight-bold"></h6>
            </div>
        </div>
    </div>
}


function SearchResultItem({item}: SearchResultItemProps) {

    let [, setQuickSearchPopupState] = useQuickSearchPopupState();
    const [currency] = useDefaultCurrencyCode();

    let {tx} = useI18n("header.search.item");

    return <WithPlaceholders value={{
        item: item,
        fraction: ((PriceConversion(currency, item.price) ?? 0) - (PriceConversion(currency, item.specialPrice) ?? 0)) / (PriceConversion(currency, item.price) ?? 0)
    }}>
        <div className="col-sm-6">
            <Link to={`/product/${item.id}`} className="nav-link p-0 mb-3"
                  onClick={() => setQuickSearchPopupState(false, 0)}>
                <div className="card p-2">
                    <div className="row">
                        <div className="col-lg-2 col-sm-3 col-3">
                            {item.iconUrl &&
                                <img src={createImgProxyUrl(item.iconUrl[0], 700, 700)} className="w-100 rounded"
                                     style={{minHeight: 60}}/>}
                        </div>
                        <div className="col-lg-10 col-sm-9 col-9">
                            <p className="m-0 d-block long-text"
                               style={{fontSize: "15px", color: 'black'}}>{tx`name|{{item.name}}`}</p>
                            {!!item.specialPrice && !!item.price && item.specialPrice < item.price && item.enableSpecialPrice ?
                                <SearchResultDetailsWithSpecialPrice/> : <SearchResultDetails/>}
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    </WithPlaceholders>
}

export function QuickSearchResults() {

    let [result] = useSearch("quick-search");
    let [results, setResults] = useState<ProductConnection>();
    let [quickSearchPopupState, setQuickSearchPopupState] = useQuickSearchPopupState();
    const [searchBarState] = useSearchBarState();
    const isMobile = useMediaQuery({query: `(max-width: 760px)`});

    let {tx} = useI18n("header.search.results");

    useEffect(() => {
        if (isSuccess(result)) {
            setResults(result.data)
        }
    }, [result])

    if (isMobile) {
        if (!quickSearchPopupState.open) return null
    } else {
        if (!quickSearchPopupState.open || !searchBarState) return null
    }

    return <WithPlaceholders value={{
        count: results?.pageInfo?.totalCount ?? 0
    }}>
        <div style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: "#00000011",
            zIndex: 5
        }} onClick={() => setQuickSearchPopupState(false, 0)}/>
        <div className={`header-second-menu__search-input-results active`} style={{top: quickSearchPopupState.top}}>
            <div className="container" style={{maxWidth: "960px"}}>
                <div className="card card-body border-0 rounded-0 shadow" style={{overflowY: "auto"}}>
                    <p>{!!results && tx`countLabel`}</p>
                    <div className="row">
                        {!!results && results.edges.map(a => a.node).map(item => <SearchResultItem item={item}/>)}
                    </div>
                </div>
            </div>
        </div>
    </WithPlaceholders>
}
