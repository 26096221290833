import React from "react";
import {Product} from "../../../../../reducers/product/types";
import {useI18n} from "../../../../../i18n/I18nSupport";

interface ProductDetailsProps{
    productData: Product
}

export function ProductDetailsSection({productData}: ProductDetailsProps) {
    const {tx, localized} = useI18n("product.info.description")

    return(
        <>
            <div className="card-body">
                <h6 className="font-weight-bold noSelect">{tx`descriptionLabel`}</h6>
                <span dangerouslySetInnerHTML={{__html: localized(productData.description) ?? ''}}/>
                {productData.videoLink &&
                <div className="row mt-3">
                    <div className="col-sm-8 offset-sm-2">
                        <iframe width="100%" height="315" src={productData.videoLink}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen/>
                    </div>
                </div>
                }
            </div>
        </>
    )
}
