import React, {useEffect, useState} from "react";
import {useSchema} from "../../../reducers/schema";
import {useI18n, WithPlaceholders} from "../../../i18n/I18nSupport";
import {isSuccess} from "../../../reducers/networkStateReducer";
import {useFindPageById} from "../../../reducers/pages/findPage";
import {Link} from "react-router-dom";
import {mediaToIcon, PageLink, PageLinkObjectProps, PageLinkProps} from "./Footer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {definition as faPhoneAlt} from "@fortawesome/free-solid-svg-icons/faPhoneAlt";
import {definition as faEnvelope} from "@fortawesome/free-solid-svg-icons/faEnvelope";
import {DateTime} from "luxon";
import {IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {useLocalStorage} from "./../Body/Checkout/CheckoutFlowRevamp/utils";

export function PageLinkRevamp({pageId, pageTitle}: PageLinkObjectProps) {
    const {tx, localized} = useI18n("footer.pageLink");

    return <WithPlaceholders value={{title: pageTitle}}>
        <li className="d-flex flex-row mt-1 mr-2">
            <Link to={`/information/page/${pageId}`} target={"_blank"}>
                <span
                    className="btn-text__text footer_btn-text footer_btn-text-default page-link-container"> {tx`title|{{title}}`} </span></Link>
        </li>

    </WithPlaceholders>

}

interface ContactInfoItemProps {
    data: string
    icon: IconDefinition
    label: JSX.Element,
    value: JSX.Element,
    data2?: string,
    value2?: JSX.Element,
    type?: ContactInfoType
}

export enum ContactInfoType {
    mail = `mailto:`, phone = `tel:`
}

function ContactInfoItem({icon, data, label, value, data2, value2, type}: ContactInfoItemProps) {


    return <a className="btn-text mr-5 d-flex flex-start" href={`${type}${data}`}>

        <div>
            <span className="btn-text__icon footer_btn-text__icon">
                <FontAwesomeIcon icon={icon} size={"lg"}/>
            </span>
        </div>
        <div>
            <span className="btn-text__text footer_btn-text footer_btn-text-default d-flex flex-column">
                <label style={{fontSize: "0.65rem", margin: "0.1rem"}}>
                    {label}
                </label>
                <label style={{fontSize: "0.9rem", fontWeight: "bold"}}>
                    {(data && value) &&
                        <a className="btn-text__text footer_btn-text footer_btn-text-default" href={`${type}${data}`}
                           style={{textDecoration: 'none', color: 'black'}}>{value}</a>}
                    {(data2 && value2) &&
                        <span><span> | </span><a className="btn-text__text footer_btn-text footer_btn-text-default"
                                                 href={`${type}${data2}`}
                                                 style={{textDecoration: 'none', color: 'black'}}>{value2}</a></span>}
                </label>
            </span>
        </div>
    </a>
}

function getIconClasses(icon: string, socialMediaWithStandardColors: boolean) {
    return socialMediaWithStandardColors ? `social-${icon}` : 'footer-button-icon';
}

export function FooterRevamp() {

    let [schema] = useSchema()
    const {tx, ts} = useI18n("footer")
    const [getItem] = useLocalStorage<{ inventoryId: string }>();

    if (!isSuccess(schema)) return null

    const footer = schema.data.footer;
    const selectedInventoryId = getItem('inventoryId')?.inventoryId;
    const selectedInventoryLocation = selectedInventoryId ? schema.data.inventoryLocations?.filter(inventory => inventory.id == selectedInventoryId)?.[0] : null

    return <WithPlaceholders value={{
        footer
    }}>
        <footer className="footer mt-auto pt-3">
            <div className="container" style={{width: "85%"}}>
                <div className="row w-100 d-flex flex-lg-row flex-column pb-4">
                    <div className="col-lg-6">
                        <div className="row footer-logo-container justify-content-start">
                            <div className="col-lg-5 col-sm-6 col-5">
                                <img src={schema.data.brandLogo} className="mb-3 w-50 footer-logo"/>
                                {selectedInventoryLocation ?
                                    <p className={"text-left"}>

                                        {selectedInventoryLocation.address?.addressLine1 &&
                                            <span><span>{selectedInventoryLocation.address?.addressLine1}</span> <br/></span>}
                                        {selectedInventoryLocation.address?.addressLine2 &&
                                            <span><span>{selectedInventoryLocation.address?.addressLine2}</span> <br/></span>}
                                        {selectedInventoryLocation.address?.city &&
                                            <span><span>{selectedInventoryLocation.address?.city}</span> <br/></span>}
                                        {selectedInventoryLocation.address?.province &&
                                            <span><span>{selectedInventoryLocation.address?.province}</span> <br/></span>}
                                        {selectedInventoryLocation.address?.postalCode &&
                                            <span><span>{selectedInventoryLocation.address?.postalCode}</span> <br/></span>}
                                    </p>
                                    :
                                    footer.address && <p className={"text-left"}>
                                        {footer.address.split(",").map(val => <span key={val}> {val} </span>)}
                                        <br/>
                                        {footer.addressTwo?.split(",").map(val => <span key={val}> {val} </span>)}
                                        <br/>
                                        {footer.city}
                                        <br/>
                                        {footer.postalCode}
                                    </p>
                                }
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-lg-row justify-content-lg-start mt-1">
                            {(!!selectedInventoryLocation?.address?.contactNumber || !!schema.data.footer.telephone) &&
                                <ContactInfoItem
                                    data={selectedInventoryLocation?.address?.contactNumber || schema.data.footer.telephone || ""}
                                    label={tx`telephoneLabel|Have a Question?`}
                                    value={selectedInventoryLocation?.address?.contactNumber ?
                                        <span>{selectedInventoryLocation?.address?.contactNumber}</span> : tx`phone|{{footer.telephone}}`}
                                    icon={faPhoneAlt}
                                    data2={selectedInventoryLocation?.address?.contactNumber2}
                                    value2={<span>{selectedInventoryLocation?.address?.contactNumber2}</span>}
                                    type={ContactInfoType.phone}
                                />
                            }
                            {!!schema.data.footer.email &&
                                <ContactInfoItem
                                    data={schema.data.footer.email}
                                    label={tx`emailLabel|Contact Us at`}
                                    value={tx`email|{{footer.email}}`}
                                    icon={faEnvelope}
                                    type={ContactInfoType.mail}
                                />
                            }
                        </div>
                        <div
                            className={"font-weight-bold mt-3 mt-lg-4 d-flex flex-wrap flex-column flex-lg-row flex-md-row"}>
                            {footer.pageLinkObjects?.map((pageObj, key) => <PageLinkRevamp pageId={pageObj.url ?? ""}
                                                                                           pageTitle={pageObj.pageTitle}
                                                                                           key={key}/>)}
                        </div>
                    </div>
                    <div className="col-lg-6 d-sm-block align-items-end">

                        {/*TODO ("Removed NewsLetter Feature on Request")*/}
                        {footer.enableNewsLetter ?? true ? <div>
                            <div>
                                <span>
                                    <p className="font-weight-bold mt-4 mt-lg-2 mb-1"
                                       style={{fontSize: "1rem"}}>{tx`newsLetterLabel|Newsletter`}</p>
                                    <p className="mt-2 mb-2"
                                       style={{fontSize: "0.8rem"}}>{tx`newsLetterInfo|Subscribe to get exclusive offers and inside info.`}</p>
                                </span>
                            </div>
                            <div className="input-group mt-lg-1 mt-3 footer-news-letter newsletter-subscribe">
                                <input className="newsletter-subscribe-input" type="text" placeholder={ts`subscribe`}/>
                                <button
                                    className="btn btn-primary subscribe-btn newsletter-subscribe-button">{tx`subscribeButtonLabel`}</button>
                            </div>
                        </div> : <></>
                        }
                        <div
                            className={"footer-social-media d-flex flex-column align-items-start justify-content-end pt-2 pb-3 pb-lg-2"}>
                            {!!footer.socialMediaConfig?.length && footer.enableSocialMediaConfigs ? <div>
                                <p className="font-weight-bold mt-2 mb-1"
                                   style={{fontSize: "0.8rem"}}>{tx`connectWithUsLabel`}</p>
                            </div> : <div/>}
                            <div>
                                <ul className="m-0 p-0">
                                    {footer.enableSocialMediaConfigs && footer.socialMediaConfig?.map(({
                                                                                                           media,
                                                                                                           link
                                                                                                       }, i) => !!footer.socialMediaConfig?.length &&
                                        <li className="d-inline">
                                            <a className={`btn-icon btn-icon-footer mt-1 ${i !== (footer.socialMediaConfig?.length - 1) ? 'mr-2' : ''} ${getIconClasses(media, !!footer.socialMediaWithStandardColors)}`}
                                               target="_blank"
                                               href={link}><FontAwesomeIcon icon={mediaToIcon(media)} size={"2x"}/></a>
                                        </li>)}
                                </ul>
                            </div>
                        </div>
                        {/*<div className="text-center mt-3 mt-lg-4">*/}
                        {/*    Copyright*/}
                        {/*    © <span>{DateTime.fromISO((new Date().toISOString())).toFormat("yyyy")}</span> {schema.data.merchantName}*/}
                        {/*    . All rights reserved. Powered by <a href={schema.data.siteUrl ?? "https://appigo.co/"}*/}
                        {/*                    target="_blank"> appiGo</a>*/}
                        {/*    /!*<>*/}
                        {/*        All rights reserved. | <a href="https://appigo.co/terms-of-service" target={"_blank"}>Terms</a> | <a href="https://appigo.co/privacy-policy" target={"_blank"}>Privacy Policy</a>*/}
                        {/*    </>*!/*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
            <div className="footer-sub-footer">
                <div className="container">
                    <div className="row">
                        <div className="col text-center">
                            Copyright
                            © <span>{DateTime.fromISO((new Date().toISOString())).toFormat("yyyy")}</span> {schema.data.merchantName}
                            . Powered by <a href={schema.data.siteUrl ?? "https://appigo.co/"}
                                            target="_blank"> appiGo</a>
                            {/*<>
                                All rights reserved. | <a href="https://appigo.co/terms-of-service" target={"_blank"}>Terms</a> | <a href="https://appigo.co/privacy-policy" target={"_blank"}>Privacy Policy</a>
                            </>*/}
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    </WithPlaceholders>
}
