import {Product, VarianceValue} from "../../../../../reducers/product/types";
import React, {useEffect, useMemo, useState} from "react";
import {chunck} from "../../Home/Widgets/Widget";
import {Link, useHistory} from "react-router-dom";
import {useSearchQuery} from "../../../../../reducers/product/search";
import {useI18n, WithPlaceholders} from "../../../../../i18n/I18nSupport";
import {useTracking} from "../../../../../tracking/trackingProvider";
import {useSchema} from "../../../../../reducers/schema";
import {isSuccess} from "../../../../../reducers/networkStateReducer";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import {createImgProxyUrl} from "../../../../common/createImageProxy";
import {Helmet} from "react-helmet-async";
import {formatSlug} from "../Product";
import {useCartPopUp} from "../../../../../reducers/ui/cartPopUp";

interface ProductInformationProps {
    product: Product
}

export function PriceSection({product}: ProductInformationProps) {

    const [schema] = useSchema()
    const {tx} = useI18n("product.info.price")

    if (isSuccess(schema)){
        if (schema.data.generalSettings?.hideProductPrice == true) {
            return <></>
        }
    }

    if (product.enableSpecialPrice && product.specialPrice) {
        return <>
            <div className="row ">
                {product.price !== undefined &&
                <div className="col w-25 d-flex flex-row " style={{alignItems: "space-between", fontSize: "12px"}}>
                    <p className="special-price-strike mt-2 mb-0 font-weight-normal price-text">{tx`price`}</p>
                    <p className="ml-2 mt-2 mb-0 discounted-price-text">{tx`percentage`}</p>
                </div>
                }
            </div>
            <h6 className="mt-2 mb-0 font-weight-bold font-enlarged mt-2 mb-0 font-weight-bold discounted-price-text font-enlarged">{tx`specialPrice`}</h6>
        </>
    } else {
        return <>
            <h6 className="mt-2 mb-0 font-weight-bold font-enlarged price-text" >
                {tx`price`}
            </h6>
        </>
    }
}

export interface VariationProps {
    label?: {[p: string]: string}
    values: VarianceValue[]
    selected?: VarianceValue
    setSelected(selected: VarianceValue): void
}

export function capitalizeVariants (label: string) {
    return `${label[0].toUpperCase().concat(label.substring(1, label.length)) }`
}

export function capitalizeText (label?: string):(string | undefined) {
    if (!!label) {
        return `${label?.[0]?.toUpperCase().concat(label?.substring(1, label?.length)) }`
    } else {
        return label
    }
}


function ColorVariation({label, selected, setSelected, values}: VariationProps) {

    const {localized} = useI18n("");
    const {track} = useTracking();

    return <>
        <p className="mt-3 mb-1 font-weight-bold">{`${localized(label)}: ${!!selected ? `${capitalizeVariants(selected.label!!)}` : ''}`}</p>
        <div className="btn-group btn-group-toggle radio-square" data-toggle="buttons">
            <div className="container">
                {chunck(5, values).map(colors =>
                    <div className="row">
                        {colors.map(value => {
                            return <><label key={value.value}
                                            className={`banner ${!!selected && selected.value === value.value ? "btn active" : "btn"}`}
                                            style={{
                                                backgroundColor: `${value.value}`,
                                                border: `${!!selected && selected.value === value.value ? "3px solid #ed1c3f" : value.value === "white" ? "3px solid #c1c7cb" : ""}`
                                            }}
                                            onClick={() => {
                                                setSelected(value);
                                                track({
                                                    type: "CustomizeProduct",
                                                    data: {
                                                        value: value?.value
                                                    }
                                                })
                                                // setQuantity(1)
                                            }}>
                                {/*<input onClick={() => {*/}
                                {/*    setSelected(value);*/}
                                {/*    setQuantity(1)*/}
                                {/*}} type="radio" name="colors" id="color1" checked/>*/}
                            </label></>
                        })}
                    </div>
                )}
            </div>
        </div>
    </>
}

function EnumVariation({label, selected, setSelected, values}: VariationProps) {

    const {localized} = useI18n("");
    const {track} = useTracking();

    return <>
        <p className="mt-3 mb-1 font-weight-bold">{`${localized(label)}: ${!!selected ? `${capitalizeVariants(selected.label!!)}` : ''}`}</p>
        <select className="border-0 px-0 py-2"
                defaultValue={selected?.value}
                onChange={e => {
                    if (!!e.target?.value) {
                        console.log(`%cVariant checking>> ${e.target?.value} - ${values.map(a => capitalizeVariants(a.label!!))} - values: ${JSON.stringify(values)}`, "color: yellow" )
                        let value = values.find(a => capitalizeVariants(a.label?.trim()!!) === e.target.value);
                        if (!!value) {
                            setSelected(value)
                            track({
                                type: "CustomizeProduct",
                                data: {
                                    value: value?.value
                                }
                            })
                        }
                    }
                }}>
            {values.map(value => <option selected={!!value.label && !!selected?.label && value.label?.trim() === selected.label?.trim()} key={value.value}>{capitalizeVariants(value.label!!)}</option> )}
        </select>
    </>
}

function RadioVariation({label, selected, setSelected, values}: VariationProps) {

    const {localized} = useI18n("");
    const {track} = useTracking();
    const[defaultSelect,setDefaultSelect] = useState("");

    useEffect(()=>{
        setDefaultSelect(`${!!selected ? `${capitalizeVariants(selected.label!!)}` : ''}`);
    },);


    return <>
        <p className="mt-3 mb-1 font-weight-bold">{`${localized(label)}: ${!!selected ? `${capitalizeVariants(selected.label!!)}` : ''}`}</p>

        <div>
        {values.map(value =>
            <>
        <label className="product-variant-radio-content">
        <input className="product-variant-radio-input border-0 px-0 py-2"
                type="radio"
                value={value.value}
                name='variant'
                checked={capitalizeVariants(value.label!!) === defaultSelect.toString()}

                onChange={e => {
                    if (e.target?.value) {
                        console.log(`%cVariant checking>> ${e.target?.value} - ${values.map(a => capitalizeVariants(a.label!!))} - values: ${JSON.stringify(values)}`, "color: blue" )
                            setSelected(value)
                            track({
                                type: "CustomizeProduct",
                                data: {
                                    value: value?.value
                                }
                            })
                    }
                }}/>
               {capitalizeVariants(value.label!!)}
        </label>
            </>
            )}
        </div>
    </>
}

function toVarianceKey(values: {[k: string]: VarianceValue}): string {
    return Object.entries(values).map(([k, v]) => `${k}_${v.label}`).join('_');
}

function toQueryParams(values: { [p: string]: VarianceValue }): string {
    return Object.entries(values).map(([k, v]) => `${formatSlug(k)}=${formatSlug(encodeURIComponent(v.value ?? ""))}`).join('&');
}

function toQueryParamsWithoutEncoding(values: { [p: string]: VarianceValue }): string {
    return Object.entries(values).map(([k, v]) => `${formatSlug(k)}=${formatSlug(v.value ?? "")}`).join('&');
}

interface ProductVariancesProps extends ProductInformationProps {
    onVarianceChange(varianceKey: string): void
}

export function ProductVariances({product, onVarianceChange}: ProductVariancesProps) {

    let [varianceConfig, setVarianceConfig] = useState<{[k: string]: VarianceValue}>();
    let [queryParamsConfig, setQueryParamsConfig] = useState<{[k: string]: VarianceValue}>();
    const [isSlugs, setIsSlug] = useState<boolean>(false)
    const [addToCartPopUpState] = useCartPopUp();

    useEffect(() => {
        if (!!product.productSlug) {
            setIsSlug(true)
            if (!!queryParamsConfig && product.productSlug) {
                if (addToCartPopUpState) {
                    onVarianceChange(toQueryParamsWithoutEncoding(queryParamsConfig ?? {}))
                } else {
                    onVarianceChange(toQueryParams(queryParamsConfig ?? {}))
                }
            }
        } else {
            if (!!varianceConfig && product.baseId) {
                onVarianceChange(toVarianceKey(varianceConfig))
                // push(`/product/${product.baseId}?varianceKey=${toVarianceKey(varianceConfig)}`)
            }
        }
    }, [JSON.stringify(queryParamsConfig), JSON.stringify(varianceConfig)])

    useEffect(() => {
        if (!!product.varianceSlug) {
            setQueryParamsConfig(product.querySearchParams)
        } else {
            setVarianceConfig(product.values)
        }
    }, [product.varianceSlug, product.varianceKey])

    return <>
        {product.variances.map(variance =>  {
            switch (variance.type) {
                case "RADIO":
                    return <RadioVariation label={variance.label}
                                          values={variance.values ?? []}
                                          selected={isSlugs ? queryParamsConfig ? queryParamsConfig[variance.label ? variance.label[i18n.language] : "" ?? ''] : undefined :
                                              varianceConfig ? varianceConfig[variance.id ?? ''] : undefined}
                                          setSelected={v => isSlugs ? setQueryParamsConfig(prevState => ({...prevState, [variance.label ? variance.label[i18n.language] : "" ?? '']: v})) :
                                              setVarianceConfig(prevState => ({...prevState, [variance.id ?? '']: v}))}/>
                case "DROPDOWN":
                    return <EnumVariation label={variance.label}
                                          values={variance.values ?? []}
                                          selected={isSlugs ? queryParamsConfig ? queryParamsConfig[variance.label ? variance.label[i18n.language] : "" ?? ''] : undefined :
                                              varianceConfig ? varianceConfig[variance.id ?? ''] : undefined}
                                          setSelected={v => isSlugs ? setQueryParamsConfig(prevState => ({...prevState, [variance.label ? variance.label[i18n.language] : "" ?? '']: v})) :
                                              setVarianceConfig(prevState => ({...prevState, [variance.id ?? '']: v}))}/>
                case "SWITCH":
                    return <ColorVariation label={variance.label}
                                           values={variance.values ?? []}
                                           selected={isSlugs ? queryParamsConfig ? queryParamsConfig[variance.label ? variance.label[i18n.language] : "" ?? ''] : undefined :
                                               varianceConfig ? varianceConfig[variance.id ?? ''] : undefined}
                                           setSelected={v => isSlugs ? setQueryParamsConfig(prevState => ({...prevState, [variance.label ? variance.label[i18n.language] : "" ?? '']: v})) :
                                               setVarianceConfig(prevState => ({...prevState, [variance.id ?? '']: v}))}/>
            }
        })}
    </>
}

export function ProductInformation({product}: ProductInformationProps) {
    const {replace, push, location} = useHistory();
    const [query, update] = useSearchQuery();
    const {i18n} = useTranslation();

    let {tx} = useI18n('product.info');
    const {ts, localized} = useI18n("product.info.description")
    let [schema, fetchSchema] = useSchema()

    const productStructuredData : any = useMemo(() => {
        return {
        "@context": "https://schema.org/",
        "@type": "Product",
        "name": `${product.seoConfiguration?.title ?? (!!product.name ? product.name[i18n.language] : "")}`,
        "image": [
            `${window.location.hostname}${createImgProxyUrl(product.seoConfiguration?.imageReference, 700, 700)}`,
            `${window.location.hostname}${createImgProxyUrl(product.iconUrl, 700, 700)}`,
            `${window.location.hostname}${product.iconUrl}`,
            `${window.location.origin}${createImgProxyUrl(product.iconUrl, 700, 700)}`
        ],
        "description": `${product.seoConfiguration?.description ?? localized(product.description)}`,
        "sku": `${product.sku}`,
        "brand": {
            "@type": "Brand",
            "name": `${product.brand?.tag}`
        },
        "releaseDate": `${product.createdDate}`,
        "productID": `${product.id}`,
        "weight": `${product.weight}`,
        "category": `${JSON.stringify(product.category)}`,
        "logo": `${product.brand?.logo?.available}`,
        "offers": {
            "@type": "Offer",
            "url": `${window.location.href}`,
            "priceCurrency": `${isSuccess(schema) ? schema.data.currency?.split("|")[0] : undefined}`,
            "price": `${!!product.specialPrice ? product.specialPrice : product.price}`,
            "itemCondition": "https://schema.org/NewCondition",
            "availability": `${(!!product.quantity ? product.quantity : "0") > 0 ? "https://schema.org/InStock" : "https://schema.org/SoldOut"}`
        }};
    }, [product, schema]);

    return <>
        <Helmet>
            <link rel="canonical" href={window.location.href} />
            <script type="application/ld+json">{JSON.stringify(productStructuredData)}</script>
            <meta property="og:image:secure_url" content={`${window.location.origin}${createImgProxyUrl(product.seoConfiguration?.imageReference ?? product.iconUrl, 600, 600)}`} />
            <meta property="twitter:url" content={window.location.href}/>
        </Helmet>

        <h1 className="mb-2 font-weight-bold" style={{fontSize: "1.5rem"}} >{tx`name`}</h1>
        {/*<ProductReview/>*/}
        { (!!product.brand?.name) && <p>{tx`brandLabel`}<Link to={`/search?tags=${encodeURIComponent(`brand/${product.brand?.tag ?? ''}`)}`} className="text-danger" style={{textDecoration: "none"}}>{tx`brand`}</Link></p>}
        <hr/>
        <PriceSection product={product}/>
        <hr/>
        {/*<Promotions/>*/}
         <ProductVariances product={product} onVarianceChange={(variances) => {
             if (!!product.productSlug) {
                 replace(`/products/${product.productSlug}?${variances}`)
             } else {
                 replace(`/product/${product.baseId}?varianceKey=${variances}`)
             }
        }}/>
        {!!product.variances.length ?  <hr/> : <></>}
    </>
}
