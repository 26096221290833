import React, {useEffect, useState} from "react";
import {Link} from 'react-router-dom'
import {createQueryParams, parseQueryParams, useSearch, useSearchQuery} from "../../../../../reducers/product/search";
import {isError, isInit, isPending, isSuccess} from "../../../../../reducers/networkStateReducer";
import {ProductCard} from "../../../../common/ProductCard";
import {Pagination} from "../../../../common/Pagination";
import {useProfileGet} from "../../../../../reducers/profile/get";
import {ConsumerProfileReq} from "../../../../../reducers/profile/updateBillingAddress";
import {WishListItem} from "../../../../../reducers/profile/updateWishListItems";
import {useSchema} from "../../../../../reducers/schema";

export interface SearchResultItem {
    businessId: string,
    name: string
    iconURL: string
    rating: number
    rateCount: number
    price: number
    tags: string[]
}

export const PAGE_SIZE = 12

function PaginationComp() {

    const [query] = useSearchQuery();
    const [results] = useSearch("result-section");

    function link(after: number) {
        return `/search?${createQueryParams({...query, first: PAGE_SIZE, after: after * PAGE_SIZE})}`
    }

    if (!isSuccess(results)) return null;

    const totalCount = results.data.pageInfo.totalCount;
    const totalPages = (totalCount ? Math.floor(totalCount / PAGE_SIZE) : 0)
    const curPage = (query.after ? Math.floor(query.after / PAGE_SIZE) : 0)

    const pageArr = [curPage -2, curPage -1, curPage, curPage + 1, curPage + 2].filter(a => a > 0 && a <= totalPages +  1)

    if ((!!totalCount && totalCount <= PAGE_SIZE )|| totalPages === 0) return null;

    return <ul className="pagination justify-content-sm-end mt-3 mb-0">
        <li className={`page-item ${curPage === 0 ? 'disabled' : ''}`}>
            <Link to={link(0)} className="page-link" tabIndex={-1} aria-disabled="true">«</Link>
        </li>
        <li className={`page-item ${curPage === 0 ? 'disabled' : ''}`}>
            <Link to={link(curPage - 1)} className="page-link" tabIndex={-1} aria-disabled="true">&lt;</Link>
        </li>
        {pageArr.map(page => <li className={`page-item ${(page - 1) == curPage ? 'active' : ''}`} key={page}><Link to={link(page -  1)} className="page-link" href="#">{page}</Link></li>)}
        <li className={`page-item ${curPage === totalPages ? 'disabled' : ''}`}>
            <Link to={link(curPage + 1)} className="page-link" >&gt;</Link>
        </li>
        <li className={`page-item ${curPage === totalPages ? 'disabled' : ''}`}>
            <Link to={link(totalPages - 1)} className="page-link" >»</Link>
        </li>
    </ul>
}

export function ResultSection() {

    const [results] = useSearch("result-section")
    const [query] = useSearchQuery();
    const [profile, getProfile] = useProfileGet()
    const [curWishList, setCurWishList] = useState<WishListItem[]>()
    const [pageSize,setPageSize] = useState<number>()
    const [schema] = useSchema();

    useEffect(() => {
        if (isSuccess(schema)) {
            setPageSize(schema.data.generalSettings?.searchPageProductSize ?? PAGE_SIZE)
        }
    },[schema])

    useEffect(() => {
        getProfile()
    },[])

    useEffect(() => {
        if (isSuccess(profile)) {
            console.log("Wish List" ,profile.data.wishList)
        }
    },[profile])

    useEffect(() => {
        if (isSuccess(profile)) {
            setCurWishList(profile.data.wishList)
        }
    },[profile])

    useEffect(() => {
        if (isSuccess(profile) && isSuccess(results)){
            let products = results.data.edges.map(({node}) => node)
            products.forEach(value => console.log(!!profile.data.wishList?.filter(v => v == {baseId:value?.baseId ?? "", varianceKey: value?.varianceKey})?.length))
        }
    },[profile,results])


    function link(after: number) {
        return `/search?${createQueryParams({...query, first: pageSize, after: after * (pageSize ?? PAGE_SIZE)})}`
    }

    if (isPending(results) || isInit(results)) {
        return null
    }

    if (isError(results)) {
        return null
    }

    return <div className="col-lg-9 col-sm-8">
        <div className="row products-section">
            {isSuccess(results) && results.data.edges.map(item =>
                <ProductCard
                    key={item.node.id}
                    item={item.node}
                    isAvailable={curWishList?.map(value => value.baseId).some((v) => v == item.node.baseId)}
                    isInStock={item.node.isInStock}
                />

            )}
        </div>
        <Pagination
            parseQueryFunc={parseQueryParams}
            link={link}
            items={results}
            retrievedItems={isSuccess(results) ? results.data.edges.length: 0}
            totalItems={isSuccess(results) ? (results.data.pageInfo.totalCount ?? 0) : 0}
            pageSize={pageSize ?? PAGE_SIZE}
        />
        {/*<Pagination/>*/}
    </div>
}
