import {get, isError, isInit, isSuccess, NetworkState, networkStateReducer, reset} from "../networkStateReducer";
import {components, operations} from "../../__generated/api";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../index";
import {useEffect, useState} from "react";
import {useCart} from "../cart/get";
import {useValidateOrder} from "../cart/validateOrder";

export const domain = "@appigo-retailer/purchase"

export type CardPurchaseResponse = components["schemas"]["CardPurchaseResponse"]

export type CashPurchaseResponse = components["schemas"]["CashPurchaseResponse"]

export type PurchaseResponse = CardPurchaseResponse | CashPurchaseResponse

export function isCardResponse(response: PurchaseResponse): response is CardPurchaseResponse  {
    return response.method == "CARD"
}

export function isCashResponse(response: PurchaseResponse): response is CashPurchaseResponse  {
    return response.method == "CASH"
}

export const paymentReducer = networkStateReducer<PurchaseResponse>(domain)

export function usePayment(): [NetworkState<PurchaseResponse>, () => void] {

    const [validationState, validate, clearValidate] = useValidateOrder();
    const [_, fetchCart] = useCart()

    useEffect(() => {
        return clearValidate;
    }, [])

    useEffect(() => {
        if (isSuccess(validationState)) {
            clearValidate()
            fetchCart()
            if (validationState.data.allowedToPlaceOrder) {
                dispatch(get(domain, "/customer-api/v2/cart/purchase"))
            }
        }
    }, [validationState])

    let dispatch = useDispatch();
    return [
        useSelector<RootState, NetworkState<PurchaseResponse>>(state => state.payment.payment),
        () => validate()
    ];
}

export function usePaymentClear() {
    const dispatch = useDispatch();
    return () => dispatch(reset(domain))
}
