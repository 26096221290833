import React, {useEffect, useState} from "react";
import {useSchema} from "../../../../reducers/schema";
import {isSuccess} from "../../../../reducers/networkStateReducer";
import {useTranslation} from 'react-i18next'
import {useDefaultLanguage} from "../../../../reducers/ui/defaultLanguage";


export function LanguageSelection() {

    const [schema] = useSchema();

    const [locale, setLocale] = useState<string>();
    const [language, setLanguage, clearLanguage] = useDefaultLanguage();

    let {i18n} = useTranslation();

    useEffect(() => {
        if (!locale && isSuccess(schema)) {
            let systemLanguage = navigator.languages.find(lang => schema.data.languages.some(({code}) => code === lang));
            if (systemLanguage) {
                setLocale(systemLanguage)
                setLanguage(systemLanguage)
            } else {
                setLocale(schema.data.languages[0]?.code)
                setLanguage(schema.data.languages[0]?.code)
            }
        }
    }, [schema])

    useEffect(() => {
        if (!!locale) {
            i18n.changeLanguage(locale)
            setLanguage(locale)
        }
    }, [locale])

    if (!isSuccess(schema)) return null

    function setLocaleFun(code: string) {
        setLocale(code)
    }

    return <div className="dropdown">
            <button className="btn btn-light login-button dropdown-toggle my-2 my-sm-0 p-2 border-0 bg-transparent"
                    type="button"
                    id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {schema.data.languages.find(({code}) => code === locale)?.label}
            </button>
            <div className="login-dropdown topbar-login-dropdown dropdown-menu shadow login-button"
                     aria-labelledby="dropdownMenuButton">
                {schema.data.languages.map(({label, code}) => <button className="dropdown-item" onClick={(e) => {
                    e.preventDefault()
                    setLocaleFun(code)
                }}> {label}</button>)}
                </div>
        </div>
}