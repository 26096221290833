import React, {useCallback, useEffect, useMemo} from "react";
import {useSchema} from "../../../../reducers/schema";
import {isSuccess} from "../../../../reducers/networkStateReducer";
import {useDefaultCurrencyCode} from "../../../../reducers/ui/defaultCurrencyCode";
import {useCart} from "../../../../reducers/cart/get";
import {useSetSelectedCurrency} from "../../../../reducers/cart/setSelectedCurrency";
import {useHistory} from "react-router-dom";


export function CurrencySelection() {

    const [schema] = useSchema();

    const [currency, setCurrency] = useDefaultCurrencyCode();
    const [cart] = useCart();
    // const [selectedCurrency, setSelectedCurrency, resetSetCurrency] = useSetSelectedCurrency();
    const {location} = useHistory();

    useEffect(() => {
        if (!currency && isSuccess(schema)) {
            let systemCurrency = schema.data.currencies.find(currency => schema.data.currency == currency) ?? schema.data.currencies[0];
            if (systemCurrency) {
                setCurrency(systemCurrency)
            } else {
                setCurrency(schema.data.currencies[0])
            }
        }
    }, [schema])

    // useEffect(() => {
    //     withCartChange()
    // },[currency])

    // useEffect(() => {
    //     if (isSuccess(selectedCurrency)) {
    //         resetSetCurrency()
    //     }
    // },[selectedCurrency])

    // let withCartChange = useCallback(() => {
    //     if (isSuccess(cart) && cart.data.selectedCurrency !== currency && (cart.data.orderStatus !== "ON_PAYMENT" && cart.data.orderStatus !== "ORDER_PLACED")) {
    //         setSelectedCurrency(currency)
    //     }
    // },[currency, cart])

    if (!isSuccess(schema)) return null

    if (location.pathname == "/checkout" || location.pathname.includes("/order/view")) return null

    return <div className="dropdown">
            <button className="btn btn-light login-button dropdown-toggle my-2 my-sm-0 p-2 border-0 bg-transparent"
                    type="button"
                    id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {(schema.data.currencies.find((curr) => curr === currency) ?? schema.data.currencies[0])?.split("|")[0]}
            </button>
            <div className="login-dropdown topbar-login-dropdown dropdown-menu shadow login-button"
                     aria-labelledby="dropdownMenuButton">
                {schema.data.currencies.map((curr) => <button className="dropdown-item" onClick={(e) => {
                    e.preventDefault()
                    setCurrency(curr)
                }}> {curr?.split("|")[0]}</button>)}
                </div>
        </div>
}