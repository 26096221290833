import {useI18n, WithPlaceholders} from "../../../../../i18n/I18nSupport";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {isMenuWithItems, Menu} from "../NavMenuItems";
import {getMoreColumnsCount} from "./MoreItemsUtil";

interface MoreMenuItemsProps {
    menus: Menu[],
    setShowMenu(state: boolean): void,
    containerWidth?: number
}

interface SideMenusProps {
    menus: Menu[]
    onClick(): void
    setSelected(menu: Menu): void
}

interface MenuItemProps {
    menu: Menu
    onClick(): void
    setSelected(menu: Menu): void
}

interface SideMenuItemsProps {
    menus: Menu[]
    selected: Menu
    onClick(): void
}

interface SideMenuItemProps {
    menu: Menu,
    onClick(): void
}

export function MoreMenuItems({menus, setShowMenu, containerWidth = window.innerWidth}: MoreMenuItemsProps) {

    const [selected, setSelected] = useState<Menu>(menus[0]);

    useEffect(() => {
        setSelected(menus[0])
    }, [menus])

    return (
        <div className="dropdown-menu dropdown-menu-more appigo-sub-menu" aria-labelledby="dropdownMenuButton4" style={{width: containerWidth}}>
            <div className="container">
                <div className="menu-content">
                    <div className="row">
                        <SideMenus menus={menus} setSelected={setSelected} onClick={()=> setShowMenu(false)}/>
                        <SideMenuItems menus={menus} selected={selected} onClick={()=> setShowMenu(false)}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

function SideMenus({menus, setSelected, onClick}: SideMenusProps) {

    return <div className="col-md-3 more-menu-items">
        <div className="row ">
            <ul className="nav tab-menu flex-column nav-pills" role="tablist" data-toggle="tab"
                aria-orientation="vertical">
                {menus.map((menu, key) => {
                    return <MenuItem menu={menu} setSelected={setSelected} onClick={onClick}/>
                })}
            </ul>
        </div>
    </div>
}

function MenuItem({menu, setSelected, onClick}: MenuItemProps) {

    let {tx} = useI18n("header.menu");

    return <WithPlaceholders value={{menu}}>
        <li onClick={()=> onClick()}>
            <Link to={menu.link} className="nav-link appigo-sub-menu-title appigo-sub-menu-button" type="button"
                  id="dropdownMenuButton1" data-toggle="tab" onMouseOver={() => setSelected(menu)}>{tx`name`}</Link>
        </li>
    </WithPlaceholders>
}

function SideMenuItems({menus, selected, onClick}: SideMenuItemsProps) {

    let {tx} = useI18n("header.menu");
    const runCallback = (cb: { (): JSX.Element[]; (): any; }) => {
        return cb();
    };

    return <div className="col-md-9 more-menu-items">
        <div className="tab-content">

            {menus.filter((menu) => JSON.stringify(menu) === JSON.stringify(selected)).map((menu, key) => {

                let subMenus = !!isMenuWithItems(menu) && getMoreColumnsCount(menu.menuItems?.length) ? getMoreColumnsCount(menu.menuItems?.length) : 1;
                let itemLength = isMenuWithItems(menu) && menu.menuItems?.length;

                if ((itemLength ?? 0 > 1)) {
                    return <WithPlaceholders value={{menu}}>
                        <div className="tab-pane fade show active" id={`item${key}`}>
                            <div className="appigo-sub-menu-title dropdown-heading">
                                <h5>{tx`name`}</h5>
                                <hr className="w-100 m-0 bg-secondary" style={{float: "left"}}/>
                            </div>
                            <div className="dropdown-a-list">
                                <div className="row">
                                    {runCallback(() => {
                                        const row = [];
                                        for (var i = 0; i < subMenus; i++) {
                                            row.push(
                                                <div className="col-4">
                                                    <ul>
                                                        {isMenuWithItems(menu) && menu.menuItems?.filter((item, index) =>
                                                            (index < (Math.ceil(menu.menuItems.length / subMenus)) * (i + 1)) && (index >= (Math.ceil(menu.menuItems.length / subMenus)) * (i))).map((item, key) =>
                                                            <SideMenuItem menu={item} key={key} onClick={onClick}/>)}
                                                    </ul>
                                                </div>
                                            );
                                        }
                                        return row;
                                    })}
                                </div>
                            </div>
                        </div>
                    </WithPlaceholders>
                } else return <div className="tab-pane fade" id={`item${key}`}>
                </div>
            })}
        </div>
    </div>
}

function SideMenuItem({menu, onClick}: SideMenuItemProps) {

    let {tx} = useI18n("header.menu");

    return <WithPlaceholders value={{menu: menu}}>
        <li className={"ad-section__product-name"} onClick={()=> onClick()}>
            <Link to={menu.link} className="appigo-sub-menu-label">{tx`name`}</Link>
        </li>
    </WithPlaceholders>
}