import React from 'react';

interface LogoProps{
    logoURL: string,
    logoAlignment:any
    showBanner?: boolean
}

export default function Logo({logoURL,logoAlignment, showBanner}: LogoProps) {
    return (
            <div>
                <span className={`navbar-brand navbar-brand__light-theme ${!showBanner ? 'mt-1' : ''}`}
                      style={{textAlign : logoAlignment == "LEFT" ? "left" : "center"}}>
                    <img src={logoURL} className={"appigo-logo"}/>
                </span>
                {/*<a className="navbar-brand navbar-brand__dark-theme" href="">*/}
                {/*    <img src="http://localhost:9999/logo-white-color.png"/>*/}
                {/*</a>*/}
            </div>
    );
}
