import React, {useEffect} from "react";
import {isError} from "../../reducers/networkStateReducer";
import {toast} from "react-toastify";

export function Loader() {

    return <div className="tab-pane fade show active" id="collections" role="tabpanel" aria-labelledby="collections-tab">
        <div className="card border-0">
            <div className="card-body d-flex justify-content-center align-items-center" style={{minHeight: 300}}>
                <div className="spinner-grow text-danger mx-1" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-danger mx-1" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-danger mx-1" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    </div>
}