import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {definition as faSearch} from "@fortawesome/free-solid-svg-icons/faSearch";

interface SearchIconProps{
    handleSearchClicked: any
}

export default function SearchIcon({handleSearchClicked}:SearchIconProps) {

    return (
        <li className="d-inline login-button bg-transparent"><a className="btn" onClick={handleSearchClicked}>
            <FontAwesomeIcon icon={faSearch} style={{fontSize: "1.25rem"}} size={"2x"}/></a></li>
    );
}