import React from 'react';
import { useSchema } from "../../../../reducers/schema";
import { isSuccess } from "../../../../reducers/networkStateReducer";
import { NavMenuItems } from "./NavMenuItems";
import SearchPopup from "../MenuBar_Old/SearchPopup";
import { toLocation } from "../../Body/Home/Banner/Banner";
import useResizeObserver from "use-resize-observer/polyfilled";
import CartPopUp from "../MenuBar_Old/CartPopUp";

interface MenuBarProps {
    setHeight(height: number): void,
    showSearchBar?: boolean,
    showCategoryBarLogo?: boolean
}

export function NavMenu({ setHeight, showSearchBar = true, showCategoryBarLogo = false }: MenuBarProps) {

    let [schema] = useSchema();
    const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();
    const { ref: logoRef, width: logoWidth = 1, height: logoHeight = 1 } = useResizeObserver<HTMLDivElement>();
    if (!isSuccess(schema)) return null

    return (
        <>
            <div className="header-nav-menu mt-1 d-lg-block d-none">
                {showCategoryBarLogo && <div style={{ position: 'absolute', height: '2rem', margin: 'auto', top: 0, bottom: 0, left: Math.max(((window.innerWidth - width) / 2) - (logoWidth + 10), 0) }}>
                    <img src={schema.data.brandLogo} style={{ height: '2rem', maxWidth: ((window.innerWidth - width) / 2) }} ref={logoRef}/>
                </div>}
                <div className="container" style={{ maxWidth: "960px" }}>
                    <div className="row no-gutters" style={{ justifyContent: showCategoryBarLogo ? 'flex-end' : toLocation(schema.data.menuAlignment) }} ref={ref}>
                        <NavMenuItems categoryItems={schema.data.header.menus} containerWidth={width} containerHeight={height} />
                        {showCategoryBarLogo &&
                            <div style={{position:"relative"}}>
                            <CartPopUp/>
                            </div>
                            }
                    </div>

                </div>
                {/*<CartPopUp />*/}

            </div>
            {showSearchBar && <SearchPopup setHeight={setHeight} />}
        </>
    );
}
